@import '../../node_modules/react-vis/dist/style.css';

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  /* font-family: 'Oswald', sans-serif; */
  font-family: 'Lato', sans-serif;
  color: #2f2e2e;
  /* background: #fafafa; */
  background: #f3f3f3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
