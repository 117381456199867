* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  /* font-family: 'Oswald', sans-serif; */
  font-family: 'Lato', sans-serif;
  color: #2f2e2e;
  /* background: #fafafa; */
  background: #f3f3f3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.list {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  width: 65ch !important;
  height: 100%;
}

.card {
  position: absolute;
  will-change: transform, height, opacity;
  width: 100%;
}

.cell {
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  padding: 15px;
}

.details {
  position: relative;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 10px 25px -10px rgba(0, 0, 0, 0.2);
}

.App {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.View {
  -webkit-flex: 1 1;
          flex: 1 1;
  opacity: 0;
  -webkit-animation: fadein 0.75s ease-in-out 0.2s forwards;
          animation: fadein 0.75s ease-in-out 0.2s forwards;
  /* margin-bottom: 67px; */
}

.View h2 {
  font-size: 3rem;
  margin-left: 2rem;
  /* text-align: center; */
}

.login {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  /* margin: 0 auto 0 20%; */
  width: 100%;
}

@media (min-width: 768px) {
  .View h2 {
    width: 95%;
    margin: 2rem auto;
  }
}

@media (min-width: 1200px) {
  .View h2 {
    width: 85%;
    font-size: 3.6rem;
    /* margin: 2rem auto; */
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

