.list {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  width: 65ch !important;
  height: 100%;
}

.card {
  position: absolute;
  will-change: transform, height, opacity;
  width: 100%;
}

.cell {
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  padding: 15px;
}

.details {
  position: relative;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 10px 25px -10px rgba(0, 0, 0, 0.2);
}
