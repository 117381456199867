.App {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.View {
  flex: 1;
  opacity: 0;
  animation: fadein 0.75s ease-in-out 0.2s forwards;
  /* margin-bottom: 67px; */
}

.View h2 {
  font-size: 3rem;
  margin-left: 2rem;
  /* text-align: center; */
}

.login {
  display: flex;
  align-items: center;
  height: 100vh;
  /* margin: 0 auto 0 20%; */
  width: 100%;
}

@media (min-width: 768px) {
  .View h2 {
    width: 95%;
    margin: 2rem auto;
  }
}

@media (min-width: 1200px) {
  .View h2 {
    width: 85%;
    font-size: 3.6rem;
    /* margin: 2rem auto; */
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
